import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { RegisterFlow } from '../../../components/views/layout'
import { Confirmation, LoadingOverlay, Seo } from '../../../components/common'
import { useRegister } from '../../../hooks'
import { Paths } from '../../../constants/structure'

import { registerFields } from '../../../common/utils/register'
import { isEmptyObject } from '../../../common/utils/functions'

const COMPANY_NAME = process.env.APP_COMPANY

const UpgradeConfirmationPage = () => {
   const { formState, reset } = useRegister()

   const isInvalid = isEmptyObject(formState)

   useEffect(() => {
      if (isInvalid) {
         navigate(Paths.REGISTER)
      }
   }, [])

   const handleStartButton = () => {
      reset()
      navigate(Paths.DASHBOARD)
   }

   return (
      <RegisterFlow fullWidth>
         <Seo title="Register - Step 3" />
         {isInvalid ? (
            <LoadingOverlay />
         ) : (
            <Confirmation
               onClick={handleStartButton}
               startLink="Paths.LOGIN"
               startText="Start"
            >
               <p>
                  A confirmation email has been sent to{' '}
                  {formState[registerFields.EMAIL]}.
               </p>
               <p>
                  We hope you enjoy using <strong>{COMPANY_NAME}</strong>.
               </p>
            </Confirmation>
         )}
      </RegisterFlow>
   )
}

export default UpgradeConfirmationPage
